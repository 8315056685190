<template>
  <div
    class="h-screen min-h-content max-w-full flex flex-col justify-start items-center bg-gray-50"
  >
    <div class="h-auto w-full max-w-full flex-grow bg-gray-50">
      <transition name="slide-fade" mode="out-in" appear>
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
